// Lib Imports
import React, { useState } from 'react';
import { Box, Button, TextInput, Text, Image } from 'grommet';
import styled, { withTheme } from 'styled-components';

// CORE Imports
import FormField from 'granite-admin/core/components/FormField';
import Divider from 'granite-admin/core/components/Divider';
import CookiesDialog from 'granite-admin/core/components/CookiesDialog';
import Avatar from 'granite-admin/core/components/Avatar';

// Page specific imports
import messages from 'messages/home';

import illustration from 'assets/Illustration.png';
import feature1 from 'assets/feature1.png';
import feature2 from 'assets/feature2.png';
import feature3 from 'assets/feature3.png';
import feature4 from 'assets/feature4.png';
import laptop from 'assets/laptop.png';
import monitor from 'assets/monitor.png';

const HeadingText = styled(Text)`
  font: normal normal bold 32px/40px Muli;
  letter-spacing: 0.88px;
`;

const Home = ({ theme }) => {
  const [cookieDialogVisible, setCookieDialogVisibility] = useState(true);

  return (
    <Box background="white" flex="grow">
      <Box
        direction="row"
        justify="start"
        background={{
          position: 'top',
          size: 'cover',
          image: `url(${illustration})`,
        }}
        height="100vh"
        width="100%"
        id="home"
      >
        <Box pad={{ top: '6rem', left: '6rem' }} margin={{ top: '80px' }} width="53%">
          <HeadingText>Secure and stable core platform to build the your unique ideas</HeadingText>
          <Text size="17px" margin={{ vertical: 'medium' }}>
            A T2B Foundation created to develop scalable business systems, from supporting startup ideas to complex
            enterprise platforms without the need to start from scratch.
          </Text>
          <Box width="75%">
            <FormField alignSelf="start" margin={{ bottom: 'medium' }} name="email" width="large">
              <TextInput placeholder={messages.EMAIL_PLACEHOLDER} type="email" name="email" />
            </FormField>
            <Button alignSelf="start" label="Start Demo" primary />
          </Box>
        </Box>
      </Box>

      <Box id="features" direction="column" gap="large" pad={{ vertical: 'xlarge', horizontal: 'xxlarge' }}>
        <Box
          alignSelf="center"
          border={{
            color: 'brand',
            size: 'medium',
            style: 'solid',
            side: 'bottom',
          }}
          margin="large"
        >
          <Text size="24px" margin="small">
            Features
          </Text>
        </Box>

        <Box direction="row" justify="between" gap="medium" margin={{ vertical: 'large', horizontal: 'medium' }}>
          <Box direction="column" gap="large">
            <Image height="140px" src={feature1}></Image>
            <Text alignSelf="center" margin={{ vertical: 'large' }} size="21px">
              Feature One
            </Text>
          </Box>
          <Box direction="column" gap="large">
            <Image height="140px" src={feature2}></Image>
            <Text alignSelf="center" margin={{ vertical: 'large' }} size="21px">
              Feature Two
            </Text>
          </Box>
          <Box direction="column" gap="large">
            <Image height="140px" src={feature3}></Image>
            <Text alignSelf="center" margin={{ vertical: 'large' }} size="21px">
              Feature Three
            </Text>
          </Box>
          <Box direction="column" gap="large">
            <Image height="140px" src={feature4}></Image>
            <Text alignSelf="center" margin={{ vertical: 'large' }} size="21px">
              Feature Four
            </Text>
          </Box>
        </Box>
      </Box>

      <Box id="services" direction="column" gap="large" pad={{ vertical: 'xlarge', horizontal: 'xxlarge' }}>
        <Box
          alignSelf="center"
          border={{
            color: 'brand',
            size: 'medium',
            style: 'solid',
            side: 'bottom',
          }}
          margin="large"
        >
          <Text size="24px" margin="small">
            Services
          </Text>
        </Box>

        <Box direction="row" justify="between" gap="medium" margin={{ vertical: 'large', horizontal: 'medium' }}>
          <Box direction="column" gap="small" basis="45%">
            <Text color="brand" alignSelf="start" margin={{ vertical: 'small' }} size="17px">
              Authentication and access control, rules pre managed.
            </Text>
            <Text color="#444444" alignSelf="start" margin={{ vertical: 'small' }} size="15px">
              It is a long established fact that a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
              letters, as opposed to using 'Content here, content here', making it look like readable English.
            </Text>
          </Box>
          <Box direction="column" gap="large">
            <Image src={laptop}></Image>
          </Box>
        </Box>

        <Box direction="row" justify="between" gap="medium" margin={{ vertical: 'large', horizontal: 'medium' }}>
          <Box direction="column" gap="large">
            <Image src={monitor}></Image>
          </Box>
          <Box direction="column" gap="small" basis="45%">
            <Text color="brand" alignSelf="start" margin={{ vertical: 'small' }} size="17px">
              React based dashboard. Admin console.
            </Text>
            <Text color="#444444" alignSelf="start" margin={{ vertical: 'small' }} size="15px">
              It is a long established fact that a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
              letters, as opposed to using 'Content here, content here', making it look like readable English.
            </Text>
          </Box>
        </Box>

        <Box direction="row" justify="between" gap="medium" margin={{ vertical: 'large', horizontal: 'medium' }}>
          <Box direction="column" gap="small" basis="45%">
            <Text color="brand" alignSelf="start" margin={{ vertical: 'small' }} size="17px">
              Authentication and access control, rules pre managed.
            </Text>
            <Text color="#444444" alignSelf="start" margin={{ vertical: 'small' }} size="15px">
              It is a long established fact that a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
              letters, as opposed to using 'Content here, content here', making it look like readable English.
            </Text>
          </Box>
          <Box direction="column" gap="large">
            <Image src={laptop}></Image>
          </Box>
        </Box>
      </Box>

      <Box id="about-us" direction="column" gap="large" pad={{ vertical: 'xlarge', horizontal: 'xxlarge' }}>
        <Box
          alignSelf="center"
          border={{
            color: 'brand',
            size: 'medium',
            style: 'solid',
            side: 'bottom',
          }}
          margin="large"
        >
          <Text size="24px" margin="small">
            About Us
          </Text>
        </Box>

        <Box direction="row" justify="between" gap="xxsmall" margin={{ vertical: 'large', horizontal: 'xxlarge' }}>
          <Box direction="column" gap="xsmall">
            <Avatar
              src={`https://www.bioid.com/wp-content/uploads/face-database-bioid.jpg`}
              size="10rem"
              round="full"
            ></Avatar>
            <Text alignSelf="center" margin={{ top: 'medium', bottom: 'xsmall' }} size="17px" color="#222D39">
              Gary Fernandez
            </Text>
            <Text color="#AFAFAF" alignSelf="center" size="13px">
              Founding Partner
            </Text>
          </Box>
          <Box direction="column" gap="xsmall">
            <Avatar
              src={`https://www.bioid.com/wp-content/uploads/face-database-bioid.jpg`}
              size="10rem"
              round="full"
            ></Avatar>
            <Text alignSelf="center" margin={{ top: 'medium', bottom: 'xsmall' }} size="17px" color="#222D39">
              Gary Fernandez
            </Text>
            <Text color="#AFAFAF" alignSelf="center" size="13px">
              Founding Partner
            </Text>
          </Box>
          <Box direction="column" gap="xsmall">
            <Avatar
              src={`https://www.bioid.com/wp-content/uploads/face-database-bioid.jpg`}
              size="10rem"
              round="full"
            ></Avatar>
            <Text alignSelf="center" margin={{ top: 'medium', bottom: 'xsmall' }} size="17px" color="#222D39">
              Gary Fernandez
            </Text>
            <Text color="#AFAFAF" alignSelf="center" size="13px">
              Founding Partner
            </Text>
          </Box>
        </Box>

        <Box direction="row" justify="between" gap="medium" margin={{ vertical: 'large', horizontal: 'medium' }}>
          <Box direction="column" gap="small">
            <Text color="brand" alignSelf="start" margin={{ vertical: 'small' }} size="17px">
              About The Company
            </Text>
            <Text color="#444444" alignSelf="start" margin={{ vertical: 'small' }} size="15px">
              It is a long established fact that a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
              letters, as opposed to using 'Content here, content here', making it look like readable English.
            </Text>
            <Text color="#444444" alignSelf="start" margin={{ vertical: 'small' }} size="15px">
              It is a long established fact that a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
              letters, as opposed to using 'Content here, content here', making it look like readable English. It is a
              long established fact that a reader will be distracted by the readable content of a page when looking at
              its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
              as opposed to using 'Content here, content here', making it look like readable English.
            </Text>
          </Box>
        </Box>
      </Box>

      <Divider background="light-4" />
      <CookiesDialog
        isVisible={cookieDialogVisible}
        onAccept={() => setCookieDialogVisibility(false)}
        onReject={() => setCookieDialogVisibility(false)}
      />
    </Box>
  );
};

export default withTheme(Home);
